import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { convertNumberBR } from '../../utils/masks'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Relatório gerado em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss') +
      '.'
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

// eslint-disable-next-line no-unused-vars
function renderCheckboxList(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório de entregas')

  doc.text(
    'Relatório de fraude',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    DataAtendimento,
    HoraInicio,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Matrícula:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(consultor, startX + smallGutterX, startY + boxHeight * 3 + gutterY)

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    DataAtendimento,
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário da entrega:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraInicio,
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  // doc.rect(
  //   startX + tableLimit / 2,
  //   startY + boxHeight * 4,
  //   line,
  //   boxHeight,
  //   'F',
  // )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

// eslint-disable-next-line no-unused-vars
function declaration(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Declaração', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Declaração:',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia ? `${relatorio.description}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    tableLimit,
    startX,
    smallGutterY,
    line,
    heightLimit,
    margin,
  } = config

  cursor += config.boxHeight + 300

  if (cursor + boxHeight * 10 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.AssinaturaProdutor) {
    produtor.src = relatorio.AssinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 200,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  doc.rect(startX + 120, cursor + 105, tableLimit / 2, line, 'F')

  if (relatorio.AssinaturaProdutor) {
    doc.text(
      relatorio.fazenda.produtor.Nome,
      tableLimit / 2,
      cursor + 110 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura produtor(a)',
      tableLimit / 2,
      cursor + 125 + smallGutterY,
      null,
      null,
      'center',
    )
  }

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 7, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      var cursor = 0
      header(doc, config, data)
      subHeader(doc, data, config)
      config.startY = config.startY + config.boxHeight * 5 + 20
      declaration(doc, data, config)
      config.startY = config.startY + config.boxHeight * 2 + 20
      cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
      const pageCount = doc.internal.getNumberOfPages()

      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        rodapeImpressao(
          doc,
          config,
          cursor + config.boxHeight,
          String(i) + ' de ' + String(pageCount),
        )
      }
      doc
        .save(
          `relatorio-de-entregas-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
          { returnPromise: true },
        )
        .then(() => {
          return resolve()
        })
    } catch (err) {
      reject(err)
    }
  })
}
