<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center">
              {{ relatorio.title ?? 'Relatório de entrega' }}</v-row
            >
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGerais :relatorio="relatorio" />
      <Description :relatorio="relatorio" />
      <ProximaVisita :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn
            color="primary"
            @click="
              () => {
                exportRel(relatorio, true)
              }
            "
          >
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
      <v-row justify="center">
        <v-dialog v-model="dialogProgressPDF" persistent max-width="430">
          <v-card>
            <v-card-title class="text-h3"> Aguarde! </v-card-title>
            <v-card-title class="text-h3">
              Estamos preparando seu relatório.
            </v-card-title>
            <v-card-text>
              <div class="text-center">
                <v-progress-circular :size="40" indeterminate color="teal">
                </v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import DeliveryReportService from '../../../../services/api/delivery'
import DeliveryReportPDF from '../../../../services/reports/DeliveryReportPDF'
import { exportReport } from '../../../../utils/report'
import Description from './Description.vue'
import InformacoesGerais from './InformacoesGerais.vue'
import ProximaVisita from './ProximaVisita.vue'

export default {
  components: {
    InformacoesGerais,
    ProximaVisita,
    Description,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogProgressPDF: false,
      loading: true,
      relatorio: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/entregas`,
      })
    } else {
      this.getRelatorio()
    }
  },

  methods: {
    DeliveryReportPDF,
    getRelatorio() {
      DeliveryReportService.find(this.codRelatorio, this.draft).then(data => {
        this.relatorio = data
        this.loading = false
      })
    },

    async exportRel(relatorio, local) {
      if (!relatorio) relatorio = this.relatorio
      this.dialogProgressPDF = local ? true : false
      await exportReport({
        report: relatorio,
        exportReport: this.DeliveryReportPDF,
      })
      this.dialogProgressPDF = false
    },
  },
}
</script>
